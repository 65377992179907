@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;700&display=swap");

#content-page a {
  @apply underline;
}

li {
  @apply flex;
}
